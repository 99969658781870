<template>
  <v-container id="nutrition-plans" fluid tag="section" class="container-full-size">
    <v-data-table
      :headers="headers"
      :items="nutritionPlansFiltered"
      :items-per-page="tableItemsPerPage"
      :page="currentPage"
      :page-count="10"
      :search="search"
      sort-by="id"
      :sort-desc="true"
      :no-data-text="str['nutrition_plans_empty']"
      :no-results-text="str['nutrition_plans_empty']"
      class="elevation-1"
      @click:row="openNutritionPlan"
      @update:page="(value) => (currentPage = value)"
      @update:items-per-page="(value) => (tableItemsPerPage = value)"
    >
      <template #top>
        <v-toolbar flat>
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="str['search']" single-line hide-details />
          <v-spacer />
          <v-btn
            v-if="editPermission && showRecipesSearch && !searchRecipePlansIds"
            color="secondary"
            dark
            class="mb-2"
            style="margin-right: 10px; width: 150px"
            @click="searchRecipe()"
          >
            {{ str['search_recipe'] }}
          </v-btn>
          <v-btn
            v-if="editPermission && showRecipesSearch && searchRecipePlansIds"
            color="secondary"
            dark
            class="mb-2"
            style="margin-right: 10px; width: 150px"
            @click="clearSearchRecipe()"
          >
            {{ str['clean_filter'] }}
          </v-btn>
          <v-btn v-if="editPermission" color="success" dark class="mb-2" @click="newNutritionPlan()">
            {{ str['add'] }}
          </v-btn>
        </v-toolbar>
        <v-toolbar v-if="showCategory || showCategory2 || showCaloriesFilter || showDaysCountFilter" flat class="toolbar-adjust">
          <v-row>
            <v-col v-if="showCategory" :cols="12" :lg="4" :md="4" :sm="12">
              <v-autocomplete
                v-model="filters.category"
                :label="categoryName ? str[categoryName] || categoryName : str['category']"
                item-text="label"
                item-value="value"
                :items="nutritionCategories"
                :no-data-text="str['no_data']"
                hide-details
                clearable
                style="max-width: 300px"
              />
            </v-col>
            <v-col v-if="showCategory2" :cols="12" :lg="4" :md="4" :sm="12">
              <v-autocomplete
                v-model="filters.category2"
                :label="category2Name ? str[category2Name] || category2Name : str['category']"
                item-text="label"
                item-value="value"
                :items="nutritionCategories2"
                :no-data-text="str['no_data']"
                hide-details
                clearable
                style="max-width: 300px"
              />
            </v-col>
            <v-col v-if="showCaloriesFilter" :cols="12" :lg="4" :md="4" :sm="12">
              <v-text-field
                v-model="filters.calories"
                append-icon="mdi-magnify"
                :label="str['calories']"
                single-line
                hide-details
                clearable
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
              />
            </v-col>
            <v-col v-if="showDaysCountFilter" :cols="12" :lg="4" :md="4" :sm="12">
              <v-autocomplete
                v-model="filters.daysCount"
                :label="str['number_of_meals']"
                item-text="label"
                item-value="value"
                :items="nutritionNumberOfMeals"
                :no-data-text="str['no_data']"
                hide-details
                clearable
                style="max-width: 300px"
              />
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-icon small class="mr-2" color="teal lighten-2" @click.stop="duplicateItem(item)">mdi-content-duplicate</v-icon>
        <v-icon small class="mr-0" color="error" @click.stop="deleteItem(item)">mdi-delete</v-icon>
      </template>
      <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'

export default {
  data() {
    const user = Utils.getUser()
    const editPermission = Utils.hasPermission('base_nutrition_edit')
    const headers = [
      { text: window.strings['id'], value: 'id' },
      { text: window.strings['name'], value: 'name', align: 'center' },
    ]
    let showRecipes = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_recipes) {
      showRecipes = true
    }
    let showCategory = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_category) {
      showCategory = true
    }
    let showCategory2 = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_category2) {
      showCategory2 = true
    }
    let showCaloriesFilter = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_calories_filter) {
      showCaloriesFilter = true
    }
    let showDaysCountFilter = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_days_count_filter) {
      showDaysCountFilter = true
    }
    let showRecipesSearch = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_recipes_search) {
      showRecipesSearch = true
    }
    let categoryName = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.category_name) {
      categoryName = user.configurations.food_plans.category_name
    }
    let category2Name = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.category2_name) {
      category2Name = user.configurations.food_plans.category2_name
    }

    if (showRecipes) {
      headers.push({
        text: window.strings['calories_kcal'],
        value: 'calories',
        align: 'center',
      })
      headers.push({
        text: window.strings['number_of_meals'],
        value: 'days_count',
        align: 'center',
      })
    }

    if (editPermission) {
      headers.push({
        text: window.strings['actions'],
        value: 'actions',
        sortable: false,
        align: 'center',
      })
    }

    return {
      str: window.strings,
      user: user,
      showRecipes: showRecipes,
      nutritionPlans: [],
      currentPage: 1,
      tableItemsPerPage: Utils.getTableCache('foodPlansBase', 'itemsPerPage') || 10,
      search: '',
      editPermission: editPermission,
      headers: headers,
      showCategory: showCategory,
      showCategory2: showCategory2,
      showCaloriesFilter: showCaloriesFilter,
      showDaysCountFilter: showDaysCountFilter,
      showRecipesSearch: showRecipesSearch,
      categoryName: categoryName,
      category2Name: category2Name,
      filters: Utils.getTableCache('foodPlansBase', 'filters') || {
        category: null,
        category2: null,
        calories: null,
        daysCount: null,
      },
      nutritionCategories: Utils.getNutritionCategories(),
      nutritionCategories2: Utils.getNutritionCategories2(),
      nutritionNumberOfMeals: Utils.getNutritionNumberOfMeals(),
      searchRecipePlansIds: Utils.getTableCache('foodPlansBase', 'searchRecipePlansIds') || null,
    }
  },
  computed: {
    nutritionPlansFiltered() {
      const self = this
      let nutritionPlans = this.nutritionPlans
      if (this.showCategory && this.filters.category) {
        nutritionPlans = nutritionPlans.filter(function (item) {
          return item.category === self.filters.category
        })
      }
      if (this.showCategory2 && this.filters.category2) {
        nutritionPlans = nutritionPlans.filter(function (item) {
          return item.category2 === self.filters.category2
        })
      }
      if (this.showCaloriesFilter && this.filters.calories) {
        nutritionPlans = nutritionPlans.filter(function (item) {
          return item.calories && item.calories.toString().includes(self.filters.calories)
        })
      }
      if (this.showDaysCountFilter && this.filters.daysCount) {
        nutritionPlans = nutritionPlans.filter(function (item) {
          return item.days_count === parseInt(self.filters.daysCount)
        })
      }
      if (this.editPermission && this.showRecipesSearch && this.searchRecipePlansIds) {
        nutritionPlans = nutritionPlans.filter(function (item) {
          return self.searchRecipePlansIds.indexOf(item.id) > -1
        })
      }
      return nutritionPlans
    },
  },
  beforeMount: function () {
    if (!this.user) {
      return false
    }
    this.$isLoading(true)
    this.getNutritionPlans(true)
  },
  beforeDestroy: function () {
    Utils.setTableCache('foodPlansBase', 'itemsPerPage', this.tableItemsPerPage)
    Utils.setTableCache('foodPlansBase', 'page', this.currentPage)
    Utils.setTableCache('foodPlansBase', 'searchRecipePlansIds', this.searchRecipePlansIds)
    Utils.setTableCache('foodPlansBase', 'filters', JSON.parse(JSON.stringify(this.filters)))
  },
  methods: {
    getNutritionPlans: function (getCachePage) {
      const self = this
      const payload = {
        fields: ['id', 'name', 'type', 'category'],
        fast: true,
      }
      if (this.showCategory2) {
        payload.fields.push('category2')
      }
      if (this.showRecipes) {
        payload.fields.push('calories')
        payload.fields.push('days_count')
      }
      Api.getFoodPlans(payload, function (response) {
        self.$isLoading(false)
        if (response.success) {
          self.nutritionPlans = response.data
          if (getCachePage) {
            self.currentPage = Utils.getTableCache('foodPlansBase', 'page') || self.currentPage
          }
        } else {
          self.nutritionPlans = []
          self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
        }
      })
    },
    openNutritionPlan: function (item) {
      const self = this
      this.getPlan(item, function (src) {
        self.$isLoading(false)
        Utils.setStorage('foodPlan', {
          data: src,
          client: null,
        })
        self.$router.navigate.push({ path: '/home/foodPlan' })
      })
    },
    newNutritionPlan: function () {
      Utils.setStorage('foodPlan', {
        data: {
          name: window.strings['nutrition_plan'],
          description: '',
          days: [],
        },
        client: null,
      })
      this.$router.navigate.push({ path: '/home/foodPlan' })
    },
    duplicateItem(item) {
      const self = this

      this.$confirm(
        window.strings['want_duplicate_nutrition_plan'] + (item ? ' "' + item.name + '"' : '') + '?',
        '',
        'info',
        Utils.getAlertOptions(true, false),
      )
        .then(() => {
          self.getPlan(item, function (src) {
            Api.newFoodPlan(src, function (response) {
              self.$isLoading(false)
              if (response.success) {
                self.getNutritionPlans()
              } else {
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            })
          })
        })
        .catch(() => {})
    },
    deleteItem(item) {
      const self = this
      const id = item.id
      const data = {
        id: id,
        client_id: null,
      }

      this.$confirm(
        window.strings['want_delete_nutrition_plan'] + (item ? ' "' + item.name + '"' : '') + '?',
        '',
        'warning',
        Utils.getAlertOptions(true, true),
      )
        .then(() => {
          self.$isLoading(true)
          Api.deleteFoodPlan(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              for (let i = 0; i < self.nutritionPlans.length; i++) {
                if (self.nutritionPlans[i].id === id) {
                  self.nutritionPlans.splice(i, 1)
                  break
                }
              }
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          })
        })
        .catch(() => {})
    },
    getPlan(plan, callback) {
      const self = this
      const payload = {
        id: plan.id,
      }
      this.$isLoading(true)
      Api.getFoodPlan(payload, function (response) {
        if (response.success) {
          callback(response.data[0])
        } else {
          self.$isLoading(false)
          self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
        }
      })
    },
    searchRecipe() {
      const self = this
      this.$prompt(
        this.str['recipe'],
        '',
        '',
        '',
        Utils.getAlertOptions(null, null, null, {
          confirm: window.strings['search'],
        }),
      )
        .then((value) => {
          self.$isLoading(true)
          Api.searchFoodPlanRecipe(
            {
              recipe: value,
            },
            function (response) {
              self.$isLoading(false)
              if (response.success) {
                self.searchRecipePlansIds = response.data.map((i) => i.id)
              } else {
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            },
          )
        })
        .catch(() => {})
    },
    clearSearchRecipe() {
      this.searchRecipePlansIds = null
    },
  },
}
</script>
